<template>
  <div class="app-container tenant-water-statistics">
    <!-- 园区租户用水统计 -->
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="freshHandler">查询</el-button>
        <el-button @click="exportHandler">导出</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
export default {
  name: "tenantWaterStatistics",
  components: {
    FinalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "yuefen",
            label: "月份",
            width: "",
          },
          {
            prop: "biaohao",
            label: "表号",
            width: "",
          },
          {
            prop: "loudong",
            label: "楼栋",
            width: "",
          },
          {
            prop: "censhu",
            label: "层数",
            width: "",
          },
          {
            prop: "fangjianhao",
            label: "房间号",
            width: "",
          },
          {
            prop: "zuhumingcheng",
            label: "租户名称",
            width: "",
          },
          {
            prop: "biaodi",
            label: "表底",
            width: "",
          },
          {
            prop: "benyueyongliang",
            label: "本月用量",
            width: "",
          },
          {
            prop: "shangyueliangshu",
            label: "上月量数",
            width: "",
          },
          {
            prop: "chayi",
            label: "差异",
            width: "",
          },
          {
            prop: "huanbizengzhanglv",
            label: "环比增长率",
            width: "",
          },
        ],
        tableData: [],
        searchLineConfig: {
          zuhumingcheng: {
            type: "input",
            label: "租户名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入租户名称",
            prefixIcon: "el-icon-search",
          },
          biaohao: {
            type: "input",
            label: "表号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入表号",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {},
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  methods: {
    exportHandler() {},
    freshHandler() {},
    tableEventHandler() {},
  },
};
</script>

<style lang="less" scoped>
.tenant-water-statistics {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>